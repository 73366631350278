'use strict';

/**
 *
 * Universal function for animation sections while scrolling
 * Waypoints dependency needed
 *
 */

var UTILS = UTILS || {};

UTILS._animationedSections = function _animationedSections() {
    var animation = {
        runAnimation: function(section, animClassName) {
            const splittedAnimClassName = animClassName.split('-');

            setTimeout(function() {
                section.classList.remove(animClassName);
            }, 1200);
            section.classList.add('animated');
            section.classList.add(splittedAnimClassName[1]);
            section.classList.add('active');

            if ( splittedAnimClassName[1] === 'caseStudy' ) {
                section.querySelector('.layer-wrapper').classList.add('expand');

                // document.querySelector('.dot-nav__link--active').classList.remove('dot-nav__link--active');
                // document.querySelector('a[href*=' + section.getAttribute('id') + ']').classList.add('dot-nav__link--active');
            }
        }
    },
    offSetSlider = '85%',
    items,
    section,
    animationClassTable = ['animation-fadeInUp', 'animation-fadeInDown', 'animation-lineListAnim', 'animation-fadeIn', 'animation-caseStudy'],
    i;

    for (i = 0; i < animationClassTable.length; i++) {
        let dotClassName = `.${animationClassTable[i]}`,
            className = animationClassTable[i];

        if (document.querySelector(dotClassName)) {
            items = document.querySelectorAll(dotClassName);

            [].forEach.call(items, function(item) {
                section = new Waypoint({
                    element: item,
                    handler: function(direction) {
                        if (direction === 'down') {
                            animation.runAnimation( item, className );
                            this.destroy();
                        }
                    },
                    offset: offSetSlider
                });
            });
        }
    }

    // Service icon animation
    if (document.querySelector('.js-icon-animation')) {
        const iconAnimations = document.querySelectorAll('.js-icon-animation');

        [].forEach.call(iconAnimations, function(iconAnimation) {
            let iconId = iconAnimation.getAttribute('id');
            new Vivus(iconId, {type: 'delayed', duration: 100}, function() {
                iconAnimation.classList.add('loaded')
            });
        });
    }

    // revealFx
    var leftReveal, rightReveal, leftObjId, rightObjId;

    if ( document.querySelector('.animation-reveal') ) {
        const items2 = document.querySelectorAll('.animation-reveal');

        [].forEach.call(items2, function(item2) {

            new Waypoint({
                element: item2,
                handler: function(direction) {
                    if(direction === 'down') {
                        leftObjId = $(item2).find('.js-reveal-left').attr('id');
                        leftReveal = new RevealFx(item2.querySelector('#' + $(item2).find('.js-reveal-left').attr('id')), {
                            revealSettings: {
                                bgcolor: item2.querySelector('.js-reveal-left').getAttribute('data-bg-color'),
                                direction: 'rl',
                                onCover: function (contentEl, revealerEl) {
                                    contentEl.style.opacity = 1;
                                }
                            }
                        });

                        rightObjId = item2.querySelector('.js-reveal-right').getAttribute('id');
                        rightReveal = new RevealFx(item2.querySelector('.js-reveal-right'), {
                            revealSettings: {
                                bgcolor: item2.querySelector('.js-reveal-right').getAttribute('data-bg-color'),
                                delay: 250,
                                onCover: function (contentEl, revealerEl) {
                                    contentEl.style.opacity = 1;
                                }
                            }
                        });
                        

                        var playPromise = item2.querySelector('video').play();

                        if (playPromise !== undefined) {
                        playPromise.then(_ => {
                        // Automatic playback started!
                        // Show playing UI.
                        })
                        .catch(error => {
                        // Auto-play was prevented
                        // Show paused UI.
                        });
                        }
  
                        leftReveal.reveal();
                        rightReveal.reveal();

                        item2.classList.remove('animation-reveal');

                        this.destroy();
                    }
                },
                offset: '90%'
            });
        });
    }
};
