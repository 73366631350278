'use strict';

/**
 *
 * Video fit - jquery needed
 *
 */

var UTILS = UTILS || {};

UTILS.videoFit = function videoFit() {
    var allVideos,
    sectionHeight = 0,
    sectionWidth = 0,
    sectionRatio = 0,
    newVideoWidth = 0,
    ratio = 2.4; // for 1920x800px videos

    if ( $('.js-video-full').length ) {
        allVideos = $('.js-video-full');

        allVideos.each(function(index) {
            var self = this,
                thisVideo = $(this)[0],
                videoWidth,
                videoHeight;

            videoWidth = thisVideo.videoWidth;
            videoHeight = thisVideo.videoHeight;

            ratio = videoWidth / videoHeight;

            $(this).css('height', 'auto').css('width', 'auto');

            sectionHeight = $(this).parent().outerHeight();
            sectionWidth = $(this).parent().outerWidth();
            newVideoWidth = ratio * sectionHeight;

            sectionRatio = (sectionWidth / sectionHeight).toFixed(3);

            if (sectionRatio > ratio) {
                $(this).css('height', sectionWidth / ratio).css('width', sectionWidth).css('max-width', sectionWidth);
            } else {
                $(this).css('height', sectionHeight).css('max-width', newVideoWidth);
            }

        });
    }
};
