'use strict';

/**
 * 
 * Infinite Scroll - jquery needed
 * 
 */

var UTILS = UTILS || {};

UTILS.loadVideos = function() {
    const videos = document.querySelectorAll('video');
    for (let i = 0; i<videos.length; i++) {
        let sourceOfVideo = videos[i].querySelectorAll('source');

        if (UTILS.isMobile() === false) {
            for (let vI = 0; vI<sourceOfVideo.length; vI++) {
                sourceOfVideo[vI].setAttribute('src', sourceOfVideo[vI].getAttribute('data-src'));
            }

            videos[i].load();
        } else {
            const poster = videos[i].getAttribute('data-poster');

            if (poster) {
                videos[i].setAttribute('poster', poster);
            }
        }
    }
};